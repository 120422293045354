/* FormComponent.css */

.container {
  max-width: 1200px; /* Increase width as needed */
  margin: 0 auto; /* Center the container horizontally */
}

h1, h2 {
  color: #00B7B7;
}

h3 {
  color: black;
}

h4{
    color: #C4A484;
}

h5{
    color: #00B7B7;
    font-family: 'Times New Roman', Times, serif;
}

h6{
  color: #00B7B7;
}
h7{
  color: #00B7B7;
  font-size: 36px;
}
h8{
  color: #00B7B7;
  font-size:20px;
}
.form-logo {
  max-width: 200px; /* Adjust size as needed */
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-check-label {
  margin-left: 0.5rem;
  text-align: left;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-check-input {
  margin-right: 0.5rem; /* Space between the checkbox and the label */
}

select {
  width: 100%; /* Set the select element to 100% width */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23999' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
  cursor: pointer;
}

button {
  width: 100%;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.075) !important;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px;
}

.category-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Styles for Customer Information */
.customer-info {
  margin-bottom: 20px;
}

.customer-info .form-group {
  display: flex;
  flex-direction: row; /* Adjust to row to ensure items align horizontally */
  gap: 20px;
  align-items: center; /* Align items vertically center */
}

.customer-info .form-group label {
  display: block;
  font-weight: bold;
}

.customer-info .form-group input,
.customer-info .form-group select {
  width: 100%; /* Ensure inputs and selects take full width of their container */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.customer-info .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23999' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
  cursor: pointer;
}

.customer-info .form-group input:focus,
.customer-info .form-group select:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.invalid-email {
  box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.7);
}
.small-text {
  font-size: 0.8em; /* Adjust the size as needed */
}

p7{
  font-weight: bold;
}